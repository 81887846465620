import React from "react";
import { Script } from "gatsby";

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      {process.env.NODE_ENV === "production" && (
        <>
          <Script id="hotjar" strategy="idle">
            {`
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3338880,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
          </Script>
          {/* <Script
            id="linkedin"
            type="text/javascript"
            strategy="idle"
            dangerouslySetInnerHTML={{
              __html: `
              _linkedin_partner_id = "1327044";
              window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
              window._linkedin_data_partner_ids.push(_linkedin_partner_id);
              `,
            }}
            />
          <Script
            type="text/javascript"
            strategy="idle"
            dangerouslySetInnerHTML={{
              __html: `
                  (function(l) {
                    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                    window.lintrk.q=[]}
                    var s = document.getElementsByTagName("script")[0];
                    var b = document.createElement("script");
                    b.type = "text/javascript";b.async = true;
                    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                    s.parentNode.insertBefore(b, s);})(window.lintrk);
                `,
            }}
          />
          <noscript>
            <img
              height={1}
              width={1}
              style={{ display: "none" }}
              alt=""
              src="https://px.ads.linkedin.com/collect/?pid=1327044&fmt=gif"
            />
          </noscript> */}
        </>
      )}
    </>
  );
};
