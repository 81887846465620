import { Crisp } from "crisp-sdk-web";

export const onRouteUpdate = ({ location, prevLocation }) => {};

export const onClientEntry = () => {
  Crisp.configure("902d804f-2a84-4bfd-9a90-57fc6027a541");
  Crisp.setColorTheme("black");
};

export * from "./gatsby-ssr-browser";
